import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PartyItemComponent from "../components/PartyItemComponent";
import PartySuspendedItemComponent from "../components/PartySuspendedItemComponent";
// import PostsCategoryBar from "../components/PostsCategoryBar";
import { Filters, initList, requestNewPage } from "../reducers/lists";
import "./Parties.css";

const MOCK_PARTIES = [
  {
    id: 1,
    partyName: "party1",
    bio: "dashdaiuhsdaiushda",
    reportCount: 12,
  },
  {
    id: 2,
    partyName: "party2",
    bio: "dashdaiuhsdaiushda",
    reportCount: 542,
  },
  {
    id: 3,
    partyName: "party3",
    bio: "dashdaiuhsdaiushda",
    reportCount: 1,
  },
  {
    id: 4,
    partyName: "party4",
    bio: "dashdaiuhsdaiushda",
    reportCount: 90,
  },
  {
    id: 5,
    partyName: "party5",
    bio: "dashdaiuhsdaiushda",
    reportCount: 37,
  },
];
const MOCK_SUSPENDED = [
  {
    id: 1,
    partyName: "party1",
    bio: "dashdaiuhsdaiushda",
    suspendedTime: "12",
  },
  {
    id: 2,
    partyName: "party2",
    bio: "dasdasda",
    suspendedTime: "0",
  },
];

function Parties() {
  const [filter, setFilter] = useState(Filters.ALL);
  const dispatch = useDispatch();

  const list = useSelector((state) => state.lists[`parties__${filter}`] ?? {});

  if (!list.initialised && !list.isLoading) {
    dispatch(initList("parties", "", filter));
  }

  const loadMore = () => {
    if (!(list.queryEnd || list.isLoading)) {
      dispatch(requestNewPage("parties", "", filter));
    }
  };

  const partylist =
    filter === Filters.SUSPENDED
      ? list?.data?.map((data, index) => (
          <PartySuspendedItemComponent
            key={data.partyId ?? `party_${index}`}
            data={data}
          />
        )) ?? null
      : list?.data?.map((data, index) => (
          <PartyItemComponent
            key={data.partyId ?? `party_${index}`}
            data={data}
          />
        )) ?? null;
  return (
    <div className="PostsListContainer">
      <h1 className="PostsListTitle">Parties</h1>

      <div className="bloc-tabs">
        <button
          className={filter === Filters.ALL ? "tabs active-tabs" : "tabs"}
          onClick={() => setFilter(Filters.ALL)}
        >
          Reported
        </button>
        <button
          className={filter === Filters.SUSPENDED ? "tabs active-tabs" : "tabs"}
          onClick={() => setFilter(Filters.SUSPENDED)}
        >
          Suspended
        </button>
      </div>

      <div className="content-tabs">
        <div className="content  active-content">
          <div className="PostsListInner">{partylist}</div>
        </div>
      </div>
      {!list.queryEnd ? (
        <button className="LoadMoreBtn" title="Load more" onClick={loadMore}>
          Load more
        </button>
      ) : null}
    </div>
  );
}

export default Parties;
