import { axInstance } from ".";

const content = {
  imageFile: async (id, size) => {
    const res = await axInstance.get(`/report/${id}/${size}/getImageFile`);
    return res.data;
  },
  videoFile: async (id) => {
    const res = await axInstance.get(`/report/${id}/getVideo`);
    return res.data;
  },
};

export default content;
