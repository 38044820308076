import React from "react";
import { daysAgo } from "../utils/index";
import ServerImage from "./ServerImage";
import "./SquadSuspendedItemComponent.css";

function SquadSuspendedItemComponent({ data }) {
  const { squadName, bio, suspendedTime, squadImage } = data;
  return (
    <div className="SquadItemContainer">
      <ServerImage id={squadImage} size="medium" className="SquadItemImage" />
      <h2 className="SquadItemUsername">Squad Name: {squadName}</h2>
      <h2 className="SquadItemUsername">Bio: {bio}</h2>
      <h2 className="SquadItemSuspendedTime">
        SUSPENDED: {daysAgo(suspendedTime)} days ago
      </h2>
    </div>
  );
}

export default SquadSuspendedItemComponent;
