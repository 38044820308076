import React from "react";
import Modal from "react-modal";
import "./ProfilePostListItem.css";
import ServerImage from "./ServerImage";
import ServerVideo from "./ServerVideo";

Modal.setAppElement("#root");

function ProfilePostListItem({ data }) {
  const { content, contentType } = data;
  const isVideo = contentType === "video";
  return (
    <div className="ProfilePostContainer">
      <div className="ProfilePostInner">
        {isVideo ? (
          <ServerVideo
            id={data.content}
            thumbnail
            className="ProfilePostImage"
          />
        ) : (
          <ServerImage
            id={data.content}
            size="medium"
            className="ProfilePostImage"
          />
        )}
      </div>
    </div>
  );
}

export default ProfilePostListItem;
