import React, { useState } from "react";
// import PostsCategoryBar from "../components/PostsCategoryBar";
import { useDispatch, useSelector } from "react-redux";
import UserItemComponent from "../components/UserItemComponent";
import UserSuspendedItemComponent from "../components/UserSuspendedItemComponent";
import { Filters, initList, requestNewPage } from "../reducers/lists";
import "./Users.css";
const MOCK_USERS_LIST = [
  {
    id: 1,
    userName: "bruce_lee",
    nickName: "Bruce Lee",
    bio: "dashdaiuhsdaiushda",
    reportCount: 12,
  },
  {
    id: 2,
    userName: "clark_superman",
    nickName: "Stepbro",
    bio: "dashdaiuhsdaiushda",
    reportCount: 542,
  },
  {
    id: 3,
    userName: "johnsnow",
    nickName: "Ion",
    bio: "dashdaiuhsdaiushda",
    reportCount: 1,
  },
  {
    id: 4,
    userName: "dasdasda",
    nickName: "DASIJDIA",
    bio: "dashdaiuhsdaiushda",
    reportCount: 90,
  },
  {
    id: 5,
    userName: "12345234231",
    nickName: "CY3oR6",
    bio: "dashdaiuhsdaiushda",
    reportCount: 37,
  },
];
const MOCK_USERS_SUSPENDED = [
  {
    id: 1,
    userName: "bruce_lee",
    nickName: "Bruce Lee",
    bio: "dashdaiuhsdaiushda",
    suspendedTime: "12",
  },
  {
    id: 2,
    userName: "clark_superman",
    nickName: "Stepbro",
    bio: "dashdaiuhsdaiushda",
    suspendedTime: "0",
  },
];

function Users() {
  const [filter, setFilter] = useState(Filters.ALL);
  const dispatch = useDispatch();

  const list = useSelector((state) => state.lists[`users__${filter}`] ?? {});

  if (!list.initialised && !list.isLoading) {
    dispatch(initList("users", "", filter));
  }

  const loadMore = () => {
    if (!(list.queryEnd || list.isLoading)) {
      dispatch(requestNewPage("users", "", filter));
    }
  };

  const userlist =
    filter === Filters.SUSPENDED
      ? list?.data?.map((data, index) => (
          <UserSuspendedItemComponent
            key={data.userId ?? `user_${index}`}
            data={data}
          />
        )) ?? null
      : list?.data?.map((data, index) => (
          <UserItemComponent key={data.userId ?? `user_${index}`} data={data} />
        )) ?? null;
  return (
    <div className="PostsListContainer">
      <h1 className="PostsListTitle">Users</h1>

      <div className="bloc-tabs">
        <button
          className={filter === Filters.ALL ? "tabs active-tabs" : "tabs"}
          onClick={() => setFilter(Filters.ALL)}
        >
          Reported
        </button>
        <button
          className={filter === Filters.SUSPENDED ? "tabs active-tabs" : "tabs"}
          onClick={() => setFilter(Filters.SUSPENDED)}
        >
          Suspended
        </button>
      </div>

      <div className="content-tabs">
        <div className="content  active-content">
          <div className="PostsListInner">{userlist}</div>
        </div>
      </div>
      {!list.queryEnd ? (
        <button className="LoadMoreBtn" title="Load more" onClick={loadMore}>
          Load more
        </button>
      ) : null}
    </div>
  );
}

export default Users;
