import React from "react";
import { daysAgo } from "../utils/index";
import "./PartySuspendedItemComponent.css";
import ServerImage from "./ServerImage";

function PartySuspendedItemComponent({ data, ...rest }) {
  const { partyName, partyImage, bio, suspendedTime } = data;
  return (
    <div className="PartyItemContainer">
      <ServerImage id={partyImage} size="medium" className="PartyItemImage" />
      <h2 className="PartyItemUsername">Party Name: {partyName}</h2>
      <h2 className="PartyItemUsername">Bio: {bio}</h2>
      <h2 className="PartyItemSuspendedTime">
        SUSPENDED: {daysAgo(suspendedTime)} days ago
      </h2>
    </div>
  );
}

export default PartySuspendedItemComponent;
