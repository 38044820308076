import React, { useEffect, useState } from "react";
import BlockedUser from "../components/BlockedUser.js";
import { lists } from "../requests";
import "./Blacklist.css";

function Blacklist() {
  const [blacklist, setBlacklist] = useState([]);
  useEffect(() => {
    lists.blacklist(null).then((res) => {
      setBlacklist(res);
      console.log({ blacklist: res });
    });
  }, []);
  // const blockList = [
  //   {
  //     id: 1,
  //     userName: "bruce_lee",
  //   },
  //   {
  //     id: 2,
  //     userName: "clark_superman",
  //   },
  //   {
  //     id: 3,
  //     userName: "johnsnow",
  //   },
  // ];
  const blocklist = blacklist.map((i) => <BlockedUser data={i} />);
  return (
    <div className="BlackListContainer">
      <h1 className="BlackListTitle">Blacklist</h1>
      <div className="BlackListInner">{blocklist}</div>
      <button title="Show more" onClick />
    </div>
  );
}

export default Blacklist;
