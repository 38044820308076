import React, { useMemo, useState } from "react";
import * as FiIcons from "react-icons/fi";
import Modal from "react-modal";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { removeItem } from "../reducers/lists";
import { suspend } from "../requests";
import ServerImage from "./ServerImage";
import "./SquadItemComponent.css";
Modal.setAppElement("#root");

function SquadItemComponent({ data, ...rest }) {
  let iconStyles = { fontSize: 20, color: "white" };
  let popupIcStyle = { fontSize: 25, color: "black" };
  const [popupApproveIsOpen, setPopupApproveIsOpen] = useState(false);
  const [popupRemoveIsOpen, setPopupRemoveIsOpen] = useState(false);
  const dispatch = useDispatch();

  const { squadName, squadImage, bio, reportCount, squadId } = data;

  const _handleApprove = useMemo(
    () => () => {
      setPopupApproveIsOpen(false);
      suspend.unsuspendSquad(squadId).then((res) => {
        dispatch(removeItem("squads", "", "squadId", squadId));
      });
    },
    [setPopupApproveIsOpen, data]
  );

  const _handleRemove = useMemo(
    () => () => {
      setPopupApproveIsOpen(false);
      suspend.suspendSquad(squadId).then((res) => {
        dispatch(removeItem("squads", "", "squadId", squadId));
      });
    },
    [setPopupRemoveIsOpen, data]
  );

  return (
    <div className="SquadItemContainer">
      <ServerImage id={squadImage} size="medium" className="SquadItemImage" />
      <h2 className="SquadItemUsername">Squad Name: {squadName}</h2>
      <h2 className="SquadItemUsername">Bio: {bio}</h2>
      <Link
        to={`/ProfilePosts/squad/${squadId}/${squadName}`}
        className="SquadItemViewPostsBtn"
      >
        View Squad Posts
      </Link>
      <div className="SquadItemSeparator" />
      <h2 className="SquadItemReportCounter">Report count: {reportCount}</h2>
      <div className="SquadItemButtonsContainer">
        <button
          className="SquadItemApproveBtn"
          onClick={() => setPopupApproveIsOpen(true)}
        >
          <FiIcons.FiCheck className="ConfirmIcn" style={iconStyles} />
          <h2 className="ConfirmTxt">Allow</h2>
        </button>
        <Modal
          isOpen={popupApproveIsOpen}
          onRequestClose={() => setPopupApproveIsOpen(false)}
          style={{
            overlay: {
              backgroundColor: "rgba(0,0,0,0.2) ",
            },
            content: {
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              marginRight: "auto",
              marginLeft: "auto",
              marginTop: "auto",
              marginBottom: "auto",
              borderRadius: 10,
              backgroundColor: "white",
              height: 350,
              width: 300,
              alignSelf: "center",
              alignItems: "center",
              justifyContent: "center",
              padding: 0,
              zIndex: 3000,
            },
          }}
        >
          <button
            className="PopupCloseBtn"
            onClick={() => setPopupApproveIsOpen(false)}
          >
            <FiIcons.FiX className="PopupCloseIc" style={popupIcStyle} />
          </button>
          <h2 className="PopupTitle">
            Are you sure you want to Allow this squad?
          </h2>
          <p className="PopupDescription">
            Your action can no longer be revoked and may have consequences for
            the system.
          </p>
          <button className="PopupApproveBtn" onClick={_handleApprove}>
            Allow Squad
          </button>
        </Modal>

        <button
          className="SquadItemDenyBtn"
          onClick={() => setPopupRemoveIsOpen(true)}
        >
          <FiIcons.FiX className="ConfirmIcn" style={iconStyles} />
          <h2 className="ConfirmTxt">Suspend</h2>
        </button>
        <Modal
          isOpen={popupRemoveIsOpen}
          onRequestClose={() => setPopupRemoveIsOpen(false)}
          style={{
            overlay: {
              backgroundColor: "rgba(0,0,0,0.2) ",
            },
            content: {
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              marginRight: "auto",
              marginLeft: "auto",
              marginTop: "auto",
              marginBottom: "auto",
              borderRadius: 10,
              backgroundColor: "white",
              height: 350,
              width: 300,
              alignSelf: "center",
              alignItems: "center",
              justifyContent: "center",
              padding: 0,
              zIndex: 3000,
            },
          }}
        >
          <button
            className="PopupCloseBtn"
            onClick={() => setPopupRemoveIsOpen(false)}
          >
            <FiIcons.FiX className="PopupCloseIc" style={popupIcStyle} />
          </button>
          <h2 className="PopupTitle">
            Are you sure you want to Suspend this squad?
          </h2>
          <p className="PopupDescription">
            Your action can no longer be revoked and may have consequences for
            the system.
          </p>
          <button className="PopupRemoveBtn" onClick={_handleRemove}>
            Suspend Squad
          </button>
        </Modal>
      </div>
    </div>
  );
}

export default SquadItemComponent;
