import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import ProfilePostListItem from "../components/ProfilePostListItem";
import { initList, requestNewPage } from "../reducers/lists";
import "./ProfilePosts.css";

const MOCK_POSTS = [
  {
    id: 1,
    content: "",
    contentType: "video",
  },
];

const ListNames = {
  user: "userProfilePosts",
  squad: "squadProfilePosts",
  party: "partyProfilePosts",
};

function ProfilePosts() {
  const {
    entityId = null,
    entityName = "Username",
    entityType = "user",
  } = useParams();
  const dispatch = useDispatch();

  const listName = ListNames[entityType];

  const list = useSelector(
    (state) =>
      state.lists[`${listName ?? "userProfilePosts"}_${entityId}_`] ?? {}
  );

  if (!list.initialised && !list.isLoading) {
    dispatch(initList(listName, entityId, ""));
  }

  const loadMore = () => {
    if (!(list.queryEnd || list.isLoading)) {
      dispatch(requestNewPage(listName, entityId, ""));
    }
  };

  const profilepost = list.map((data, index) => (
    <ProfilePostListItem key={data.postId ?? `post_${index}`} data={data} />
  ));
  return (
    <div className="ProfilePostsContainer">
      <h1 className="ProfilePostsUsername">{entityName}</h1>
      <div className="ProfilePostsInner">{profilepost}</div>
      {!list.queryEnd ? (
        <button className="LoadMoreBtn" title="Load more" onClick={loadMore}>
          Load more
        </button>
      ) : null}
    </div>
  );
}

export default ProfilePosts;
