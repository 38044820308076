import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import {
  Error404,
  PrivateRoute,
  RenderIfAuthenticated,
} from "./components/heperComponents";
import LogoutButton from "./components/LogoutButton";
import Navbar from "./components/Navbar";
import Login from "./Login";
import Blacklist from "./pages/Blacklist";
import Parties from "./pages/Parties";
import Posts from "./pages/Posts";
import ProfilePosts from "./pages/ProfilePosts";
import Squads from "./pages/Squads";
import Users from "./pages/Users";
import store from "./store";

function App() {
  return (
    <>
      <Provider store={store}>
        <Router>
          <RenderIfAuthenticated>
            <Navbar />
          </RenderIfAuthenticated>
          <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/Login" component={Login} />
            <PrivateRoute path="/Posts" exact component={Posts} />
            <PrivateRoute path="/Users" component={Users} />
            <PrivateRoute path="/Squads" component={Squads} />
            <PrivateRoute path="/Parties" component={Parties} />
            <PrivateRoute path="/Blacklist" component={Blacklist} />
            <PrivateRoute path="/ProfilePosts" component={ProfilePosts} />
            <Route path="*" component={Error404} />
          </Switch>
          <RenderIfAuthenticated>
            <LogoutButton />
          </RenderIfAuthenticated>
        </Router>
      </Provider>
    </>
  );
}

export default App;
