import { axInstance } from ".";

const lists = {
  blacklist: async (cursor = null) => {
    const res = await axInstance.get(`/report/${cursor}/blacklist/users`);
    return res.data;
  },
  posts: async (cursor = null, filter = "all") => {
    const res = await axInstance.get(`/report/${cursor}/${filter}/posts`);
    return res.data;
  },
  users: async (cursor = null, filter = "all") => {
    const res = await axInstance.get(`/report/${cursor}/${filter}/users`);
    return res.data;
  },
  squads: async (cursor = null, filter = "all") => {
    const res = await axInstance.get(`/report/${cursor}/${filter}/squads`);
    return res.data;
  },
  parties: async (cursor = null, filter = "all") => {
    const res = await axInstance.get(`/report/${cursor}/${filter}/parties`);
    return res.data;
  },
  userPosts: async (cursor = null) => {
    const res = await axInstance.get(`/report/${cursor}/userPosts`);
    return res.data;
  },
  partyPosts: async (cursor = null) => {
    const res = await axInstance.get(`/report/${cursor}/partyPosts`);
    return res.data;
  },
  squadPosts: async (cursor = null) => {
    const res = await axInstance.get(`/report/${cursor}/squadPosts`);
    return res.data;
  },
};

export default lists;
