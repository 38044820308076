import React, { useMemo, useState } from "react";
import * as FiIcons from "react-icons/fi";
import Modal from "react-modal";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { removeItem } from "../reducers/lists";
import { suspend } from "../requests";
import "./PartyItemComponent.css";
import ServerImage from "./ServerImage";

Modal.setAppElement("#root");

function PartyItemComponent({ data }) {
  let iconStyles = { fontSize: 20, color: "white" };
  let popupIcStyle = { fontSize: 25, color: "black" };
  const [popupApproveIsOpen, setPopupApproveIsOpen] = useState(false);
  const [popupRemoveIsOpen, setPopupRemoveIsOpen] = useState(false);
  const dispatch = useDispatch();
  const { partyName, partyImage, bio, reportCount, partyId } = data;

  const _handleApprove = useMemo(
    () => () => {
      setPopupApproveIsOpen(false);
      suspend.unsuspendParty(partyId).then((res) => {
        dispatch(removeItem("parties", "", "partyId", partyId));
      });
    },
    [setPopupApproveIsOpen, data]
  );

  const _handleRemove = useMemo(
    () => () => {
      setPopupApproveIsOpen(false);
      suspend.suspendParty(partyId).then((res) => {
        dispatch(removeItem("parties", "", "partyId", partyId));
      });
    },
    [setPopupRemoveIsOpen, data]
  );

  return (
    <div className="PartyItemContainer">
      <ServerImage id={partyImage} size="medium" className="PartyItemImage" />
      <h2 className="PartyItemUsername">Party Name: {partyName}</h2>
      <h2 className="PartyItemUsername">Bio: {bio}</h2>
      <Link
        to={`/ProfilePosts/party/${partyId}/${partyName}`}
        className="PartyItemViewPostsBtn"
      >
        View Party Posts
      </Link>
      <div className="PartyItemSeparator" />
      <h2 className="PartyItemReportCounter">Report count: {reportCount}</h2>
      <div className="PartyItemButtonsContainer">
        <button
          className="PartyItemApproveBtn"
          onClick={() => setPopupApproveIsOpen(true)}
        >
          <FiIcons.FiCheck className="ConfirmIcn" style={iconStyles} />
          <h2 className="ConfirmTxt">Allow</h2>
        </button>
        <Modal
          isOpen={popupApproveIsOpen}
          onRequestClose={() => setPopupApproveIsOpen(false)}
          style={{
            overlay: {
              backgroundColor: "rgba(0,0,0,0.2) ",
            },
            content: {
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              marginRight: "auto",
              marginLeft: "auto",
              marginTop: "auto",
              marginBottom: "auto",
              borderRadius: 10,
              backgroundColor: "white",
              height: 350,
              width: 300,
              alignSelf: "center",
              alignItems: "center",
              justifyContent: "center",
              padding: 0,
              zIndex: 3000,
            },
          }}
        >
          <button
            className="PopupCloseBtn"
            onClick={() => setPopupApproveIsOpen(false)}
          >
            <FiIcons.FiX className="PopupCloseIc" style={popupIcStyle} />
          </button>
          <h2 className="PopupTitle">
            Are you sure you want to Allow this party?
          </h2>
          <p className="PopupDescription">
            Your action can no longer be revoked and may have consequences for
            the system.
          </p>
          <button className="PopupApproveBtn" onClick={_handleApprove}>
            Allow Party
          </button>
        </Modal>
        <button
          className="PartyItemDenyBtn"
          onClick={() => setPopupRemoveIsOpen(true)}
        >
          <FiIcons.FiX className="ConfirmIcn" style={iconStyles} />
          <h2 className="ConfirmTxt">Suspend</h2>
        </button>
        <Modal
          isOpen={popupRemoveIsOpen}
          onRequestClose={() => setPopupRemoveIsOpen(false)}
          style={{
            overlay: {
              backgroundColor: "rgba(0,0,0,0.2) ",
            },
            content: {
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              marginRight: "auto",
              marginLeft: "auto",
              marginTop: "auto",
              marginBottom: "auto",
              borderRadius: 10,
              backgroundColor: "white",
              height: 350,
              width: 300,
              alignSelf: "center",
              alignItems: "center",
              justifyContent: "center",
              padding: 0,
              zIndex: 3000,
            },
          }}
        >
          <button
            className="PopupCloseBtn"
            onClick={() => setPopupRemoveIsOpen(false)}
          >
            <FiIcons.FiX className="PopupCloseIc" style={popupIcStyle} />
          </button>
          <h2 className="PopupTitle">
            Are you sure you want to Suspend this party?
          </h2>
          <p className="PopupDescription">
            Your action can no longer be revoked and may have consequences for
            the system.
          </p>
          <button className="PopupRemoveBtn" onClick={_handleRemove}>
            Suspend Party
          </button>
        </Modal>
      </div>
    </div>
  );
}

export default PartyItemComponent;
