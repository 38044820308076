import { newPageArrived, requestNewPageError } from "../reducers/lists";
import Lists from "../store/listsRegistry";

const logErr = (listType, entityId, cursor, err) =>
  console.trace(
    "error fetching list",
    listType,
    entityId,
    "cursor",
    cursor,
    ": ",
    err
  );

const getCursor = (store, listType, entityId, filter) => {
  const { lists } = store.getState();
  const listId = `${listType}_${entityId}_${filter}`;
  const descriptor = lists[listId];
  if (!descriptor) {
    return null;
  }
  return descriptor.cursor;
};

const listsMiddleware = () => {
  return (store) => (next) => (action) => {
    switch (action.type) {
      case "INIT_LIST": {
        const { listType = "", entityId = "", filter = "all" } = action;
        const listTypeDescriptor = Lists[listType];
        if (!listTypeDescriptor) {
          return next(action);
        }

        if (listTypeDescriptor.requiresId) {
          listTypeDescriptor
            .fetchNewBatch(entityId, null, filter)
            .then(({ cursor: newCursor, res }) =>
              store.dispatch(
                newPageArrived(listType, entityId, filter, newCursor, res)
              )
            )
            .catch((err) => {
              store.dispatch(requestNewPageError(listType, entityId, filter));
              logErr(listType, entityId, null, err);
            });
        } else {
          listTypeDescriptor
            .fetchNewBatch(null, filter)
            .then(({ cursor: newCursor, res }) =>
              store.dispatch(
                newPageArrived(listType, entityId, filter, newCursor, res)
              )
            )
            .catch((err) => {
              store.dispatch(requestNewPageError(listType, entityId, filter));
              logErr(listType, entityId, null, err);
            });
        }
        return next(action);
      }
      case "REQUEST_NEW_PAGE": {
        const { listType = "", entityId = null, filter = "all" } = action;
        const cursor = getCursor(store, listType, entityId, filter);
        const listTypeDescriptor = Lists[listType];
        if (!listTypeDescriptor) {
          return next(action);
        }

        if (listTypeDescriptor.requiresId) {
          listTypeDescriptor
            .fetchNewBatch(entityId, cursor, filter)
            .then(({ cursor: newCursor, res }) =>
              store.dispatch(
                newPageArrived(listType, entityId, filter, newCursor, res)
              )
            )
            .catch((err) => {
              store.dispatch(requestNewPageError(listType, entityId, filter));
              logErr(listType, entityId, cursor, err);
            });
        } else {
          listTypeDescriptor
            .fetchNewBatch(cursor, filter)
            .then(({ cursor: newCursor, res }) =>
              store.dispatch(
                newPageArrived(listType, entityId, filter, newCursor, res)
              )
            )
            .catch((err) => {
              store.dispatch(requestNewPageError(listType, entityId, filter));
              logErr(listType, entityId, cursor, err);
            });
        }

        return next(action);
      }

      default:
        return next(action);
    }
  };
};

export default listsMiddleware();
