import React from "react";
import * as FiIcons from "react-icons/fi";

let iconStyles = { fontSize: 23 };
export const SidebarData = [
  {
    title: "Posts",
    path: "/Posts",
    icon: <FiIcons.FiImage style={iconStyles} />,
    cName: "nav-text",
  },
  {
    title: "Users",
    path: "/Users",
    icon: <FiIcons.FiUsers style={iconStyles} />,
    cName: "nav-text",
  },
  {
    title: "Squads",
    path: "/Squads",
    icon: <FiIcons.FiCircle style={iconStyles} />,
    cName: "nav-text",
  },
  {
    title: "Parties",
    path: "/Parties",
    icon: <FiIcons.FiGlobe style={iconStyles} />,
    cName: "nav-text",
  },
  {
    title: "Blacklist",
    path: "/Blacklist",
    icon: <FiIcons.FiAlertTriangle style={iconStyles} />,
    cName: "nav-text",
  },
];
