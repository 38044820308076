import React, { useMemo, useState } from "react";
import * as FiIcons from "react-icons/fi";
import Modal from "react-modal";
import { useDispatch } from "react-redux";
import { removeItem } from "../reducers/lists";
import { suspend } from "../requests";
import ServerImage from "./ServerImage";
import ServerVideo from "./ServerVideo";
import "./SmallPostComponent.css";

Modal.setAppElement("#root");

function SmallPostComponent({ data }) {
  const dispatch = useDispatch();
  let iconStyles = { fontSize: 20, color: "white" };
  let popupIcStyle = { fontSize: 25, color: "white" };
  let videoIconStyle = {
    fontSize: 60,
    color: "rgba(255,255,255,0.8)",
  };
  const [popupApproveIsOpen, setPopupApproveIsOpen] = useState(false);
  const [popupRemoveIsOpen, setPopupRemoveIsOpen] = useState(false);
  const [imageModalIsOpen, setImageModalIsOpen] = useState(false);

  const _handleApprove = useMemo(
    () => () => {
      setPopupApproveIsOpen(false);
      suspend.unsuspendPost(data.postId).then((res) => {
        dispatch(removeItem("posts", "", "postId", data.postId));
      });
    },
    [setPopupApproveIsOpen, data]
  );

  const _handleRemove = useMemo(
    () => () => {
      setPopupApproveIsOpen(false);
      suspend.purgePost(data.postId).then((res) => {
        dispatch(removeItem("posts", "", "postId", data.postId));
      });
    },
    [setPopupRemoveIsOpen, data]
  );

  const isVideo = data.contentType === "video";
  return (
    <div className="SmallPostContainer">
      <div className="SmallPostInner">
        <FiIcons.FiPlay className="VideoBtn" style={videoIconStyle} />
        <button
          className="SmallPostImageModal"
          onClick={() => setImageModalIsOpen(true)}
        >
          {isVideo ? (
            <ServerVideo
              id={data.content}
              thumbnail
              className="SmallPostImage"
            />
          ) : (
            <ServerImage
              id={data.content}
              size="medium"
              className="SmallPostImage"
            />
          )}
        </button>
        <Modal
          isOpen={imageModalIsOpen}
          onRequestClose={() => setImageModalIsOpen(false)}
          style={{
            overlay: {
              backgroundColor: "rgba(0,0,0,0.2) ",
            },
            content: {
              position: "absolute",
              marginRight: "auto",
              marginLeft: "auto",
              marginTop: 60,
              borderRadius: 5,
              backgroundColor: "black",
              maxWidth: 700,
              alignSelf: "center",
              alignItems: "center",
              justifyContent: "center",
              padding: 0,
            },
          }}
        >
          {isVideo ? (
            <ServerVideo id={data.content} className="VideoPopup" />
          ) : (
            <ServerImage
              id={data.content}
              size="medium"
              className="ImagePopup"
            />
          )}

          <button
            className="ImageModalCloseBtn"
            onClick={() => setImageModalIsOpen(false)}
          >
            <FiIcons.FiX className="PopupCloseIc" style={popupIcStyle} />
          </button>
        </Modal>

        <h2 className="SmallPostUsername">{data.userName}</h2>
        <h2 className="SmallPostReportCounter">
          Report count: {data.reportCount}
        </h2>
        <div className="SmallPostButtonsContainer">
          <button
            className="SmallPostApproveBtn"
            onClick={() => setPopupApproveIsOpen(true)}
          >
            <FiIcons.FiCheck className="ConfirmIcn" style={iconStyles} />
            <h2 className="ConfirmTxt">Allow</h2>
          </button>
          <Modal
            isOpen={popupApproveIsOpen}
            onRequestClose={() => setPopupApproveIsOpen(false)}
            style={{
              overlay: {
                backgroundColor: "rgba(0,0,0,0.2) ",
              },
              content: {
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                marginRight: "auto",
                marginLeft: "auto",
                marginTop: "auto",
                marginBottom: "auto",
                borderRadius: 10,
                backgroundColor: "white",
                height: 350,
                width: 300,
                alignSelf: "center",
                alignItems: "center",
                justifyContent: "center",
                padding: 0,
                zIndex: 3000,
              },
            }}
          >
            <button
              className="PopupCloseBtn"
              onClick={() => setPopupApproveIsOpen(false)}
            >
              <FiIcons.FiX className="PopupCloseIc" style={popupIcStyle} />
            </button>
            <h2 className="PopupTitle">
              Are you sure you want to Allow this post?
            </h2>
            <p className="PopupDescription">
              Your action can no longer be revoked and may have consequences for
              the system.
            </p>
            <button className="PopupApproveBtn" onClick={_handleApprove}>
              Allow Post
            </button>
          </Modal>
          <button
            className="SmallPostDenyBtn"
            onClick={() => setPopupRemoveIsOpen(true)}
          >
            <FiIcons.FiX className="ConfirmIcn" style={iconStyles} />
            <h2 className="ConfirmTxt">Remove</h2>
          </button>
          <Modal
            isOpen={popupRemoveIsOpen}
            onRequestClose={() => setPopupRemoveIsOpen(false)}
            style={{
              overlay: {
                backgroundColor: "rgba(0,0,0,0.2) ",
              },
              content: {
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                marginRight: "auto",
                marginLeft: "auto",
                marginTop: "auto",
                marginBottom: "auto",
                borderRadius: 10,
                backgroundColor: "white",
                height: 350,
                width: 300,
                alignSelf: "center",
                alignItems: "center",
                justifyContent: "center",
                padding: 0,
                zIndex: 3000,
              },
            }}
          >
            <button
              className="PopupCloseBtn"
              onClick={() => setPopupRemoveIsOpen(false)}
            >
              <FiIcons.FiX className="PopupCloseIc" style={popupIcStyle} />
            </button>
            <h2 className="PopupTitle">
              Are you sure you want to Remove this post?
            </h2>
            <p className="PopupDescription">
              Your action can no longer be revoked and may have consequences for
              the system.
            </p>
            <button className="PopupRemoveBtn" onClick={_handleRemove}>
              Remove Post
            </button>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default SmallPostComponent;
