import React from "react";
import { daysAgo } from "../utils/index";
import ServerImage from "./ServerImage";
import "./UserSuspendedItemComponent.css";

function UserSuspendedItemComponent({ data }) {
  const { userName, userImage, nickName, bio, suspendedTime } = data;
  return (
    <div className="UserItemContainer">
      <ServerImage id={userImage} size="medium" className="UserItemImage" />
      <h2 className="UserItemUsername">Username: {userName}</h2>
      <h2 className="UserItemUsername">Nickname: {nickName}</h2>
      <h2 className="UserItemUsername">Bio: {bio}</h2>
      <h2 className="UserItemSuspendedTime">
        SUSPENDED: {daysAgo(suspendedTime)} days ago
      </h2>
    </div>
  );
}

export default UserSuspendedItemComponent;
