import * as React from "react";
import * as FiIcons from "react-icons/fi";
import { useDispatch } from "react-redux";
import { logout as logoutAction } from "../reducers/auth";

const LOGOUT_IC = { color: "white", fontSize: 23 };

export default function LogoutButton(props) {
  const dispatch = useDispatch();
  const logout = () => {
    dispatch(logoutAction());
  };
  return (
    <button onClick={logout} className="logoutBtn">
      <FiIcons.FiLogOut style={LOGOUT_IC} />
    </button>
  );
}
