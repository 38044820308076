import {
  loginFail,
  loginSuccess,
  logout,
  refreshTokenSuccess,
} from "../reducers/auth";
import { authentication, axInstance } from "../requests";

const authMiddleware = () => {
  return (store) => (next) => (action) => {
    switch (action.type) {
      case "LOGIN": {
        authentication
          .login(action.id, action.password)
          .then((res) => {
            if (res.admin) {
              console.log("login", { res });
              store.dispatch(loginSuccess(res.token, res.refreshToken));
              axInstance.defaults.headers.common.Authorization = `Bearer ${res.token}`;
            } else {
              store.dispatch(
                loginFail({ message: "Account has no admin pivileges" })
              );
            }
          })
          .catch((error) => {
            console.log("Login error", error);
            store.dispatch(loginFail(error));
          });
        return next(action);
      }
      case "LOGOUT": {
        return next(action);
      }

      case "REFRESH_TOKEN": {
        authentication
          .refreshToken(action.refreshToken)
          .then((res) => {
            store.dispatch(refreshTokenSuccess(`Bearer ${res.access_token}`));
            axInstance.defaults.headers.common.Authorization = `Bearer ${res.access_token}`;
          })
          .catch((err) => {
            console.log("Error refreshing token", err);
            alert("Error refreshing token, please login again");
            store.dispatch(logout());
          });
        return next(action);
      }

      default:
        return next(action);
    }
  };
};

export default authMiddleware();
