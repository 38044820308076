import React, { useEffect, useState } from "react";
import { getVideo } from "../store";
const ServerVideo = ({ id, thumbnail = false, ...rest }) => {
  const [source, setSource] = useState(null);

  useEffect(() => {
    if (id) {
      getVideo(id)
        .then((res) => setSource(res))
        .catch((err) => console.log("video file error", err));
    }
  }, []);

  if (!source) {
    return null;
  }

  if (thumbnail) {
    return <img src={source.thumbnail} style={rest.style} />;
  }
  return (
    <video {...rest} controls>
      <source src={source.preview} />
      Your browser does not support the video tag.
    </video>
  );
};

export default ServerVideo;
