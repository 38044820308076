import { lists } from "../requests";

const Lists = {
  users: {
    fetchNewBatch: lists.users,
    requiresId: false,
  },
  parties: {
    fetchNewBatch: lists.parties,
    requiresId: false,
  },
  posts: {
    fetchNewBatch: lists.posts,
    requiresId: false,
  },
  squads: {
    fetchNewBatch: lists.squads,
    requiresId: false,
  },
  userProfilePosts: {
    fetchNewBatch: lists.userPosts,
    requiresId: true,
  },
  squadProfilePosts: {
    fetchNewBatch: lists.squadPosts,
    requiresId: true,
  },
  partyProfilePosts: {
    fetchNewBatch: lists.partyPosts,
    requiresId: true,
  },
};

export default Lists;
