import React, { useEffect, useState } from "react";
import { getImage } from "../store";
const ServerImage = ({ id, size = "small", ...rest }) => {
  const [source, setSource] = useState(null);

  useEffect(() => {
    if (id) {
      getImage(id, size)
        .then((res) => setSource(res))
        .catch((err) => console.log("image file error", err));
    }
  }, []);

  return <img {...rest} src={source} />;
};

export default ServerImage;
