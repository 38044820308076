import * as React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router";

export function Error404() {
  return <h1>Error 404: Page not found</h1>;
}

export function InitialRedirect() {
  const isAuthenticated = useSelector(
    (state) => state.auth.isAuthenticated ?? false
  );
  if (isAuthenticated) return <Redirect to="/Posts" />;
  return <Redirect to="Login" />;
}

export function PrivateRoute({ children, ...rest }) {
  const isAuthenticated = useSelector(
    (state) => state.auth.isAuthenticated ?? false
  );
  if (!isAuthenticated) return <Redirect to="Login" />;
  return <Route {...rest} render={children} />;
}

export function RenderIfAuthenticated({ children }) {
  const isAuthenticated = useSelector(
    (state) => state.auth.isAuthenticated ?? false
  );
  if (!isAuthenticated) return null;
  return children;
}
