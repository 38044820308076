import Lists from "../store/listsRegistry";

// export interface ListObject {
//   queryEnd: boolean;
//   listType: string;
//   entityId: string;
//   cursor: any;
//   data: any[];
//   isLoading: boolean;
//   initialised: boolean;
// }

const DEFAULT_STATE = {
  // listID_filter: {
  //     listType: 'following',
  //     entityId:
  //     cursor:
  //     data: []
  //     queryEnd:
  //     isLoading:
  // }
};

const lists = (state = DEFAULT_STATE, action) => {
  const listTypeDescriptor = Lists[action.listType];
  if (!listTypeDescriptor) {
    return state;
  }
  const { entityId = null, filter = "all" } = action;
  const listId = `${action.listType}_${entityId}_${filter}`;

  let currentDescriptor;
  if (!state[listId]) {
    currentDescriptor = {
      listType: action.listType,
      entityId,
      cursor: null,
      data: [],
      queryEnd: false,
      isLoading: false,
      initialised: false,
    };
  } else {
    currentDescriptor = { ...state[listId] };
  }

  switch (action.type) {
    case "INIT_LIST": {
      currentDescriptor.isLoading = true;
      currentDescriptor.data = [];
      return Object.assign({}, state, { [listId]: currentDescriptor });
    }

    case "REQUEST_NEW_PAGE": {
      currentDescriptor.isLoading = true;
      //currentDescriptor.initialised = true;
      return Object.assign({}, state, { [listId]: currentDescriptor });
    }

    case "REQUEST_NEW_PAGE_ERROR": {
      currentDescriptor.isLoading = false;
      currentDescriptor.initialised = true;
      return Object.assign({}, state, { [listId]: currentDescriptor });
    }

    case "NEW_PAGE_ARRIVED": {
      currentDescriptor.cursor = action.newCursor;
      currentDescriptor.isLoading = false;
      currentDescriptor.initialised = true;
      if (!action.newBatch?.length) {
        currentDescriptor.queryEnd = true;
        return Object.assign({}, state, { [listId]: currentDescriptor });
      }

      currentDescriptor.data = [...currentDescriptor.data, ...action.newBatch];
      return Object.assign({}, state, { [listId]: currentDescriptor });
    }

    case "REMOVE_ITEM": {
      const { listType = null, toFindKey = "", toFindValue = "" } = action;
      const keysInQuestion = Object.keys(state).filter((key) =>
        key.startsWith(listType)
      );
      const modifiedLists = {};
      keysInQuestion.map((key) => {
        const descriptor = { ...state[key] };
        descriptor.data = descriptor.data.filter(
          (i) => i[toFindKey] !== toFindValue
        );
        modifiedLists[key] = descriptor;
      });
      return Object.assign({}, state, modifiedLists);
    }

    case "INVALIDATE_LIST": {
      return Object.assign({}, state, { [listId]: undefined });
    }

    default:
      return state;
  }
};

export default lists;

export const initList = (listType, entityId, filter) => ({
  type: "INIT_LIST",
  listType,
  entityId,
  filter,
});

export const requestNewPage = (listType, entityId, filter) => ({
  type: "REQUEST_NEW_PAGE",
  listType,
  entityId,
  filter,
});

export const requestNewPageError = (listType, entityId, filter) => ({
  type: "REQUEST_NEW_PAGE_ERROR",
  listType,
  entityId,
  filter,
});

export const newPageArrived = (
  listType,
  entityId,
  filter,
  newCursor,
  newBatch
) => ({
  type: "NEW_PAGE_ARRIVED",
  listType,
  entityId,
  newCursor,
  newBatch,
  filter,
});

export const removeItem = (listType, entityId, toFindKey, toFindValue) => ({
  type: "REMOVE_ITEM",
  listType,
  entityId,
  toFindKey,
  toFindValue,
});

export const invalidateList = (listType, entityId, filter) => ({
  type: "INVALIDATE_LIST",
  listType,
  entityId,
  filter,
});

export const Filters = {
  ALL: "all",
  FILTER_0: "0",
  FILTER_1: "1",
  FILTER_2: "2",
  SUSPENDED: "suspended",
};

//Actions:
//REQUEST_NEW_BATCH(listType, entityId)
//NEW_BATCH_ARRIVED(listType, entityId, newCursor, newBatch)
//REMOVE_ITEM (listType, entityId, propertyName, propertyId)
//
