import { content } from "../requests";

const imageCache = {};
const videoCache = {};

export async function getImage(id, size, refreshLink = false) {
  const imageFile = imageCache[id];
  if (!imageFile) {
    const imageLink = await content.imageFile(id, size);
    imageCache[id] = { [size]: imageLink };
    return imageCache[id][size];
  }
  if (!imageFile[size] || refreshLink) {
    const imageLink = await content.imageFile(id, size);
    Object.assign(imageFile[id], { [size]: imageLink });
    return imageCache[id][size];
  }

  return imageFile[size];
}

export async function getVideo(id, refreshLink = false) {
  const videoFile = videoCache[id];
  if (!videoFile) {
    const videoData = await content.videoFile(id);
    videoCache[id] = videoData;
    return videoData;
  }

  return videoFile;
}
