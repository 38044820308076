import axios from "axios";
import authentication from "./authentication";
import content from "./content";
import lists from "./lists";
import suspend from "./suspend";

const apiUrl = "https://europe-west1-blizzup-2885d.cloudfunctions.net/api";

const axInstance = axios.create({
  baseURL: apiUrl,
});

export { authentication, axInstance, content, lists, suspend };
