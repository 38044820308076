const DEFAULT_STATE = {
  isAuthenticated: false,
  token: null,
  refreshToken: null,
  error: null,
};

const auth = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case "LOGIN_FAKE": {
      return { ...state, isAuthenticated: true };
    }
    case "LOGIN_SUCCESS": {
      if (action.token && action.refreshToken)
        return {
          isAuthenticated: true,
          token: action.token,
          refreshToken: action.refreshToken,
          error: null,
        };
      return state;
    }
    case "LOGIN_FAIL": {
      return { ...state, error: action.error };
    }
    case "REFRESH_TOKEN_SUCCESS": {
      return { ...state, error: null, token: action.newToken };
    }
    case "LOGOUT": {
      return DEFAULT_STATE;
    }
    default:
      return state;
  }
};

export default auth;

export const login = (id, password) => ({
  type: "LOGIN",
  id,
  password,
});

export const loginSuccess = (token, refreshToken) => ({
  type: "LOGIN_SUCCESS",
  token,
  refreshToken,
});

export const loginFail = (error) => ({
  type: "LOGIN_FAIL",
  error,
});

export const logout = () => ({
  type: "LOGOUT",
});

export const refreshToken = (refreshToken) => ({
  type: "REFRESH_TOKEN",
  refreshToken,
});

export const refreshTokenSuccess = (newToken) => ({
  type: "REFRESH_TOKEN_SUCCESS",
  newToken,
});

export const loginFake = () => ({ type: "LOGIN_FAKE" });
