import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import "./Login.css";
import { login as loginAction } from "./reducers/auth";
const logo = require("./images/loginlogo.png");

function Login() {
  const loginIdField = useRef();
  const loginPasswordField = useRef();

  const dispatch = useDispatch();
  const history = useHistory();

  const { isAuthenticated = false, error = null } = useSelector(
    (state) => state.auth ?? {}
  );
  useEffect(() => {
    if (isAuthenticated) {
      history.push("/posts");
    }
  }, [isAuthenticated, history]);

  const login = () => {
    const password = loginPasswordField.current?.value;
    const id = loginIdField.current?.value;
    dispatch(loginAction(id, password));
  };
  let headingStyle = { marginTop: 20 };
  return (
    <>
      <div className="TopHeader">
        <img src={logo} className="Logo" alt="logo" />
        <h1 className="Title">Report Center</h1>
      </div>
      <div className="Container">
        <div className="InputContainer">
          <h1 className="Heading">Id</h1>
          <input
            ref={loginIdField}
            type="text"
            placeholder="Id@blizzup.com..."
            className="Input"
          />
          <h1 className="Heading" style={headingStyle}>
            Password
          </h1>
          <input
            ref={loginPasswordField}
            type="password"
            placeholder="Password..."
            className="Input"
          />
          <div className="errorContainer">
            {error ? <p className="errorTxt">Error: {error?.message}</p> : null}
          </div>
        </div>
        <button onClick={login} className="LoginBtn">
          <h1 className="LoginTxt">Login</h1>
        </button>
      </div>
    </>
  );
}

export default Login;
