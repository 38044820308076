import { axInstance } from ".";

const suspend = {
  suspendPost: async (id) => {
    const res = axInstance.put(`/report/post/${id}/suspend`);
    return res;
  },
  suspendUser: async (id) => {
    const res = axInstance.put(`/report/user/${id}/suspend`);
    return res;
  },
  suspendParty: async (id) => {
    const res = axInstance.put(`/report/party/${id}/suspend`);
    return res;
  },
  suspendSquad: async (id) => {
    const res = axInstance.put(`/report/squad/${id}/suspend`);
    return res;
  },

  unsuspendPost: async (id) => {
    const res = axInstance.put(`/report/post/${id}/unsuspend`);
    return res;
  },
  unsuspendUser: async (id) => {
    const res = axInstance.put(`/report/user/${id}/unsuspend`);
    return res;
  },
  unsuspendParty: async (id) => {
    const res = axInstance.put(`/report/party/${id}/unsuspend`);
    return res;
  },
  unsuspendSquad: async (id) => {
    const res = axInstance.put(`/report/squad/${id}/unsuspend`);
    return res;
  },

  purgePost: async (id) => {
    const res = axInstance.put(`/report/post/${id}/purge`);
    return res;
  },
};

export default suspend;
