import React from "react";
import "./BlockedUser.css";

function BlockedUser({ blockUser }) {
  return (
    <div className="BlockedUserContainer">
      <div className="BlockedUserImage" />
      <h2 className="BlockedUserName">{blockUser.userName}</h2>
    </div>
  );
}

export default BlockedUser;
