import { applyMiddleware, compose, createStore } from "redux";
import middleware from "../middleware/index";
import rootReducer from "../reducers";
import { getImage, getVideo } from "./contentCache";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?? compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middleware))
);
export { getImage, getVideo };
export default store;
