import axios from "axios";
import { axInstance } from ".";

const REFRESH_URL =
  "https://securetoken.googleapis.com/v1/token?key=AIzaSyAqcCkZk17yQrrZEntKBYCSzWBSKY2YByo";

const authentication = {
  login: async (id, password) => {
    const res = await axInstance.post("/auth/login", {
      email: id,
      password: password,
    });
    return res.data;
  },

  logout: async () => {
    const res = await axInstance.get("");
    return res.data;
  },

  refreshToken: async (rt) => {
    const res = await axios.post(
      REFRESH_URL,
      {
        grant_type: "refresh_token",
        refresh_token: rt,
      },
      {
        headers: {
          Authorization: "",
        },
      }
    );
    return res.data;
  },
};

export default authentication;
