import React, { useMemo, useState } from "react";
import * as FiIcons from "react-icons/fi";
import Modal from "react-modal";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { removeItem } from "../reducers/lists";
import suspend from "../requests/suspend";
import ServerImage from "./ServerImage";
import "./UserItemComponent.css";

Modal.setAppElement("#root");

function UserItemComponent({ data = {}, ...rest }) {
  let iconStyles = { fontSize: 20, color: "white" };
  let popupIcStyle = { fontSize: 25, color: "black" };
  const [popupApproveIsOpen, setPopupApproveIsOpen] = useState(false);
  const [popupRemoveIsOpen, setPopupRemoveIsOpen] = useState(false);
  const dispatch = useDispatch();

  const { userName, nickName, userImage, bio, reportCount, userId } = data;

  const _handleApprove = useMemo(
    () => () => {
      setPopupApproveIsOpen(false);
      suspend.unsuspendUser(userId).then((res) => {
        dispatch(removeItem("users", "", "userId", userId));
      });
    },
    [setPopupApproveIsOpen, data]
  );

  const _handleRemove = useMemo(
    () => () => {
      setPopupApproveIsOpen(false);
      suspend.suspendUser(userId).then((res) => {
        dispatch(removeItem("users", "", "userId", userId));
      });
    },
    [setPopupRemoveIsOpen, data]
  );

  return (
    <div className="UserItemContainer">
      <ServerImage id={userImage} size="medium" className="UserItemImage" />
      <h2 className="UserItemUsername">Username: {userName}</h2>
      <h2 className="UserItemUsername">Nickname: {nickName}</h2>
      <h2 className="UserItemUsername">Bio: {bio}</h2>
      <Link
        to={`/ProfilePosts/user/${userId}/${userName}`}
        className="UserItemViewPostsBtn"
      >
        View Profile Posts
      </Link>
      <div className="UserItemSeparator" />
      <h2 className="UserItemReportCounter">Report count: {reportCount}</h2>
      <div className="UserItemButtonsContainer">
        <button
          className="UserItemApproveBtn"
          onClick={() => setPopupApproveIsOpen(true)}
        >
          <FiIcons.FiCheck className="ConfirmIcn" style={iconStyles} />
          <h2 className="ConfirmTxt">Allow</h2>
        </button>

        <Modal
          isOpen={popupApproveIsOpen}
          onRequestClose={() => setPopupApproveIsOpen(false)}
          style={{
            overlay: {
              backgroundColor: "rgba(0,0,0,0.2) ",
            },
            content: {
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              marginRight: "auto",
              marginLeft: "auto",
              marginTop: "auto",
              marginBottom: "auto",
              borderRadius: 10,
              backgroundColor: "white",
              height: 350,
              width: 300,
              alignSelf: "center",
              alignItems: "center",
              justifyContent: "center",
              padding: 0,
              zIndex: 3000,
            },
          }}
        >
          <button
            className="PopupCloseBtn"
            onClick={() => setPopupApproveIsOpen(false)}
          >
            <FiIcons.FiX className="PopupCloseIc" style={popupIcStyle} />
          </button>
          <h2 className="PopupTitle">
            Are you sure you want to Allow this user?
          </h2>
          <p className="PopupDescription">
            Your action can no longer be revoked and may have consequences for
            the system.
          </p>
          <button className="PopupApproveBtn" onClick={_handleApprove}>
            Allow User
          </button>
        </Modal>

        <button
          className="UserItemDenyBtn"
          onClick={() => setPopupRemoveIsOpen(true)}
        >
          <FiIcons.FiX className="ConfirmIcn" style={iconStyles} />
          <h2 className="ConfirmTxt">Suspend</h2>
        </button>
        <Modal
          isOpen={popupRemoveIsOpen}
          onRequestClose={() => setPopupRemoveIsOpen(false)}
          style={{
            overlay: {
              backgroundColor: "rgba(0,0,0,0.2) ",
            },
            content: {
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              marginRight: "auto",
              marginLeft: "auto",
              marginTop: "auto",
              marginBottom: "auto",
              borderRadius: 10,
              backgroundColor: "white",
              height: 350,
              width: 300,
              alignSelf: "center",
              alignItems: "center",
              justifyContent: "center",
              padding: 0,
              zIndex: 3000,
            },
          }}
        >
          <button
            className="PopupCloseBtn"
            onClick={() => setPopupRemoveIsOpen(false)}
          >
            <FiIcons.FiX className="PopupCloseIc" style={popupIcStyle} />
          </button>
          <h2 className="PopupTitle">
            Are you sure you want to Suspend this user?
          </h2>
          <p className="PopupDescription">
            Your action can no longer be revoked and may have consequences for
            the system.
          </p>
          <button className="PopupRemoveBtn" onClick={_handleRemove}>
            Suspend User
          </button>
        </Modal>
      </div>
    </div>
  );
}

export default UserItemComponent;
