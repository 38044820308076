import { combineReducers } from "redux";
import auth from "./auth";
import lists from "./lists";

const appReducer = combineReducers({ auth, lists });

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
